import revive_payload_client_g5yrYgyAqR from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.2_encoding@0.1.13_eslint@9.10.0_jiti@1.21_cbfmefdbemp4luojfieo4hvbli/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_NAJvV0JUAS from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.2_encoding@0.1.13_eslint@9.10.0_jiti@1.21_cbfmefdbemp4luojfieo4hvbli/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_eEcYxXmvID from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.2_encoding@0.1.13_eslint@9.10.0_jiti@1.21_cbfmefdbemp4luojfieo4hvbli/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_fbFwSz15aj from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.2_encoding@0.1.13_eslint@9.10.0_jiti@1.21_cbfmefdbemp4luojfieo4hvbli/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_9DAQz6q2bO from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.2_encoding@0.1.13_eslint@9.10.0_jiti@1.21_cbfmefdbemp4luojfieo4hvbli/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_dShNYmSKnw from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.2_encoding@0.1.13_eslint@9.10.0_jiti@1.21_cbfmefdbemp4luojfieo4hvbli/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/onboarding/.nuxt/components.plugin.mjs";
import prefetch_client_mY6bxpqFxr from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.2_encoding@0.1.13_eslint@9.10.0_jiti@1.21_cbfmefdbemp4luojfieo4hvbli/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_j6bxBwVVGu from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@3.29.5_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_PnMh5ekE8p from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@3.29.5_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_OqxKcNNVbe from "/app/node_modules/.pnpm/nuxt-viewport@2.1.6_magicast@0.3.5_rollup@3.29.5_vue@3.4.38_typescript@5.5.4_/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_ILwvKHvbMa from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.2_@parcel+watcher@2.4.1_@types+node@20.11.30_encoding@0.1.13_h3@1.12.0_ior_hycaxwdq7jenanhpw4wjxfib3a/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_WS4dEEh625 from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node_23arkitjwmwwy2g66vmtq6gdya/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import _1_gigya_plugin_client_U491P69K7H from "/app/layers/base/plugins/1.gigya-plugin.client.ts";
import _2_user_session_validation_client_BYPNrXf3eW from "/app/layers/base/plugins/2.user-session-validation.client.ts";
import bootstrap_client_n243PjUQtC from "/app/layers/base/plugins/bootstrap.client.ts";
import focus_visible_client_JIkamKZ8y6 from "/app/layers/base/plugins/focus-visible.client.ts";
import http_interceptor_9I5iYs0has from "/app/layers/base/plugins/http-interceptor.ts";
import _0_gigya_plugin_client_VZq2p4KDAf from "/app/apps/onboarding/plugins/0.gigya-plugin.client.ts";
import image_thumbnail_update_G2rT6oNNpa from "/app/apps/onboarding/plugins/image-thumbnail-update.ts";
import page_view_event_client_sZGbScZEgF from "/app/apps/onboarding/plugins/page-view-event.client.ts";
export default [
  revive_payload_client_g5yrYgyAqR,
  unhead_NAJvV0JUAS,
  router_eEcYxXmvID,
  payload_client_fbFwSz15aj,
  navigation_repaint_client_9DAQz6q2bO,
  chunk_reload_client_dShNYmSKnw,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mY6bxpqFxr,
  switch_locale_path_ssr_j6bxBwVVGu,
  i18n_PnMh5ekE8p,
  plugin_client_OqxKcNNVbe,
  plugin_ILwvKHvbMa,
  plugin_WS4dEEh625,
  _1_gigya_plugin_client_U491P69K7H,
  _2_user_session_validation_client_BYPNrXf3eW,
  bootstrap_client_n243PjUQtC,
  focus_visible_client_JIkamKZ8y6,
  http_interceptor_9I5iYs0has,
  _0_gigya_plugin_client_VZq2p4KDAf,
  image_thumbnail_update_G2rT6oNNpa,
  page_view_event_client_sZGbScZEgF
]