export const useOnboardingAuth = () => {
  const isUserAuthenticated = async () => {
    const res: any = await $fetch('/apigateway/authenticated');
    setDataFromUserAuthenticatedCall(res);
    return res;
  };

    const setDataFromUserAuthenticatedCall = (data: any) => {
        useState('isUserAuthenticationChecked').value = true;
        if (data.userAuthenticated) {
            useState('userData').value = data.user;
        }
        useState('isUserAuthenticatedCall').value = undefined;
    };

    // function to not trigger multiple isUserAuthenticated calls
    const checkUserAuthenticatedCache = async () => {
        const isUserAuthenticatedCall: any = useState('isUserAuthenticatedCall');
        if (!useState('isUserAuthenticationChecked').value) {
            if (!isUserAuthenticatedCall.value) {
                const res = await isUserAuthenticated();
                isUserAuthenticatedCall.value = res.user;
            }
            return isUserAuthenticatedCall.value;
        } else {
            return Promise.resolve(useState('userData').value);
        }
    };

    const setUserData = (user: any) => {
        useState('userData').value = user;
    };

    const getUserData = () => {
        return useState('userData').value;
    };

    const setIsGigyaLoaded = (value: boolean) => {
        useState('isGigyaLoaded').value = value;
    };

  // cleans userData
  const clearUserdata = () => {
    useState('userData').value = false;
  };
  const getParentConsentGranted = () => {
    return useState('parent-consent-accepted').value;
  }

  const setParentConsentGranted = (accepted: boolean) => {
    useState('parent-consent-accepted').value = accepted;
  }

  return {
    setIsGigyaLoaded,
    isUserAuthenticated,
    checkUserAuthenticatedCache,
    clearUserdata,
    setUserData,
    getUserData,
    setDataFromUserAuthenticatedCall,
    getParentConsentGranted,
    setParentConsentGranted
  };
};