<script setup lang="ts">
// This is an error page, created to redirect to home whenever user tries to access any invalid url
// This page can also be modified in case we need a customised UX to reflect error to the user
import type { NuxtError } from '#app';

const props = defineProps({
  error: Object as () => NuxtError
});
//This console log is not to be removed since it is needed to find the reason of error that happens during SSR
if (props?.error) {
  console.log(
    'Redirecting to home from error.vue',
    JSON.stringify(props?.error)
  );
  console.log(
    'Redirecting to home from error stack ',
    JSON.stringify(props?.error?.stack)
  );
}
navigateTo('/home');
</script>
