// @ts-nocheck
import countrydata from './../assets/data/country-data.json';
export function publishOnboardingGigyaMeta() {
  const CountriesCodeMap = {};
  const CountriesNameMap = {};

  countrydata.forEach(function (item) {
    CountriesCodeMap[item.country] = item.code;
    CountriesNameMap[item.code] = item.country;
  });

  /*An array containing all the countries - Country names + Alternate Names + Relevancy Points 
      ["Afghanistan", "Afghanistan AF افغانستان"],
      ["Åland Islands", "Åland Islands AX Aaland Aland", 0.5],
      ["Albania", "Albania AL"],
    */
  const CountryList_W = [];

  function getAllCountryNames() {
    return CountriesNameMap;
  }

  function getAllCountyCodes() {
    return CountriesCodeMap;
  }

  function getCountryCode(countryName) {
    return CountriesCodeMap[countryName];
  }

  function getCountryName(countryCode) {
    return CountriesNameMap[countryCode];
  }

  function getCountryListForSearch() {
    if (CountryList_W.length == 0) {
      countrydata.forEach(function (el) {
        const item = el.weightage
          ? [el.country, el.searchCountry, el.weightage]
          : [el.country, el.searchCountry];
        CountryList_W.push(item);
      });
    }

    return CountryList_W;
  }
  const globalTranslations = {
    EN: {
      PASSWORD_MASK: 'Hide',
      PASSWORD_UNMASK: 'Show',
      GIGYA_ERRORS: {
        "NETWORK_ERROR": "Sorry, we can’t connect you at the moment. Please check you’re online and try again",
        "USERNAME_TEST_FAILED": "This should be at least 3 characters, only letters and numbers",
        "RESET_PASSWORD_INVALID_EMAIL": "Please provide your account’s email address"
      }
    },
    ES: {
      PASSWORD_MASK: 'Ocultar',
      PASSWORD_UNMASK: 'Mostrar',
      GIGYA_ERRORS: {
        "NETWORK_ERROR": "Lamentablemente no podemos conectarte en este momento. Verifica que estás en línea y vuelve a intentar.",
        "USERNAME_TEST_FAILED": "Debe tener 3 caracteres como mínimo, y solo alfanuméricos",
        "RESET_PASSWORD_INVALID_EMAIL": "Indica la dirección de email de tu cuenta"
      }
    }
  };

  const translate = function (key) {
    const { getCookie } = useHttpHelper();
    const lang = window.C1?.locale || getCookie('c1_lang') || 'en';
    return (
      globalTranslations[lang.toUpperCase()] &&
      globalTranslations[lang.toUpperCase()][key]
    );
  };

  try {
    window['C1'] = {
      STATIC_ASSETS_PATH: useRuntimeConfig().public.cdnURL
        ? useRuntimeConfig().public.cdnURL + '/_nuxt/'
        : '_nuxt/assets/img/',
      roleFromEduelt: (list: any[]) => {
        if (!Array.isArray(list)) return;
        const item = list.find((e) => e.platform == 'nemo' && !e.key_s);
        return item?.role;
      },
      countryDataHelper: {
        countrydata: countrydata,
        getAllCountryNames: getAllCountryNames,
        getAllCountyCodes: getAllCountyCodes,
        getCountryCode: getCountryCode,
        getCountryName: getCountryName,
        getCountryListForSearch: getCountryListForSearch
      },
      gigyaTranslate: translate
    };
  } catch (err) {
    console.log(err);
  }

  return { getCountryName };
}
