const { navigateToMicroAppClientOnly } = useNavigation();
const { lang, loggedInUserRole } = useLocalStorage();

export function useInterAppNavigationService() {
  /**
   * Navigates to the dashboard app with the specified path parameters and options.
   * @param pathParams The path parameters for the navigation.
   * @param options The options for the navigation.
   */
  const navigateToDashboardApp = (pathParams: any[], options: any = null) => {
    navigateToApp('dashboard', pathParams, options);
  };

  /**
   * Navigates to the admin app with the specified path parameters and options.
   * @param pathParams The path parameters for the admin app.
   * @param options The options for navigation. Default value is null.
   */
  const navigateToAdminApp = (pathParams: any[], options: any = null) => {
    navigateToApp('admin', pathParams, options);
  };

  /**
   * Navigates to the ielts app with the specified path parameters and options.
   * @param pathParams The path parameters for the ielts app.
   * @param options The options for navigation. Default value is null.
   */
  const navigateToIeltsApp = (pathParams: any[], options: any = null) => {
    navigateToApp('ielts', pathParams, options);
  };

  /**
   * Navigates to the support admin app with the specified path parameters and options.
   * @param pathParams The path parameters for the navigation.
   * @param options The options for the navigation. Default value is null.
   */
  const navigateToSupportAdminApp = (
    pathParams: any[],
    options: any = null
  ) => {
    navigateToApp('support-admin', pathParams, options);
  };

  /**
   * Navigates to the voila app with the specified path parameters and options.
   * @param pathParams The path parameters for the voila app.
   * @param options The options for navigation. Default value is null.
   */
  const navigateToVoilaApp = (pathParams: any[], options: any = null) => {
    const appUrl = useRuntimeConfig().public.separateApps.voila;
    navigateToMicroAppClientOnly(pathParams, appUrl, options);
  };

  /**
   * Navigates to the learning path app with the specified path parameters and options.
   * @param pathParams The path parameters for the learningPath app.
   * @param options The options for navigation. Default value is null.
   */
  const navigateToLearningPathApp = (
    pathParams: any[],
    options: any = null
  ) => {
    navigateToApp('learningPath', pathParams, options);
  };

  /**
   * Navigates to the class app with the specified path parameters and options.
   * @param pathParams The path parameters for the class app.
   * @param options The options for navigation. Default value is null.
   */
  const navigateToClassApp = (pathParams: any[], options: any = null) => {
    navigateToApp('class', pathParams, options);
  };

  /**
   * Navigates to the foc app with the specified path parameters and options.
   * @param pathParams The path parameters for the foc app.
   * @param options The options for navigation. Default value is null.
   */
  const navigateToFocApp = (pathParams: any[], options: any = null) => {
    navigateToApp('foc', pathParams, options);
  };

  /**
   * Navigates to the nlp app with the specified path parameters and options.
   * @param pathParams The path parameters for the nlp app.
   * @param options The options for navigation. Default value is null.
   */
  const navigateToNlpApp = (pathParams: any[], options: any = null) => {
    navigateToApp('nlp', pathParams, options);
  };

  interface AppUrls {
    [key: string]: string;
  }

  const navigateToApp = (
    appType: string,
    pathParams: any[],
    options: any = null
  ) => {
    const appUrl = (
      useRuntimeConfig().public.microapps.urlContracts as AppUrls
    )[appType];
    navigateToMicroAppClientOnly(pathParams, appUrl, options);
  };

  /**
   * Navigates the user based on their role.
   *
   * @param role - The role of the user.
   */
  const navigateUserBasedOnRole = async (role: string) => {
    localStorage.removeItem(loggedInUserRole);
    switch (role) {
      case 'student': {
        navigateToDashboardApp(['learner', 'dashboard']);
        break;
      }
      case 'superadmin': {
        localStorage.setItem(loggedInUserRole, 'superadmin');
        navigateToSupportAdminApp(['dashboard']);
        break;
      }
      case 'teacher': {
        const languageCookie = useCookie('c1_lang');
        if (languageCookie) {
          languageCookie.value = null;
        }

        // Default tab
        let tab = 'dashboard';

        const { isUserAuthenticated } = useAuthService();

        // Await the result of isUserAuthenticated() to retrieve user data
        const userData = await isUserAuthenticated();

        // Destructure the role and extUserId from the user object within userData
        const { extUserId } = userData.user;

        // Generate a key for local storage using the user's external ID (extUserId)
        const key = useLocalStorage().dashboard(extUserId);

        // Retrieve the last visited tab data from localStorage using the key
        const lastVisitedTab = localStorage.getItem(key);

        // If last visited tab data exists and contains a valid selectedTab, update the tab to selectedTab
        if (lastVisitedTab) {
          const { selectedTab } = JSON.parse(lastVisitedTab);
          if (selectedTab) {
            tab = selectedTab;
          }
        }

        localStorage.removeItem(lang);
        localStorage.setItem(loggedInUserRole, 'teacher');
        navigateToDashboardApp(['teacher', tab]);
        break;
      }
      case 'parent': {
        navigateToDashboardApp(['parent', 'dashboard']);
        break;
      }
      case 'admin': {
        localStorage.setItem(loggedInUserRole, 'admin');
        navigateToAdminApp(['admin', 'dashboard']);
        break;
      }
    }
  };
  return {
    navigateToDashboardApp,
    navigateToIeltsApp,
    navigateToAdminApp,
    navigateToSupportAdminApp,
    navigateToVoilaApp,
    navigateToLearningPathApp,
    navigateToClassApp,
    navigateToFocApp,
    navigateToNlpApp,
    navigateUserBasedOnRole
  };
}
