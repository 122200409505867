export default defineNuxtPlugin(() => {
  // onNuxtReady(async () => {
  /***
   * Once GIGYA is loaded, it will call 'onGigyaServiceReady'. This is as per official GIGYA documentation - https://developers.gigya.com/display/GD/Advanced+Customizations+and+Localization
   * Do the following things once GIGYA is loaded
   * 1. Call standard GIGYA event handlers e.g. "onLogin"
   * 2. Fire a "gigyaLoaded" event on window. This will be used by Nuxt components to do neccessary stuff e.g. enabling "Login" and "Get Started" buttons, showing user name in header
   ****/
  // @ts-ignore
  window['onGigyaServiceReady'] = function () {
    const gigyaLoadedEvent = new CustomEvent('gigyaLoaded', {
      detail: {},
      bubbles: false,
      cancelable: false
    });
    window.dispatchEvent(gigyaLoadedEvent);
  };

  // This is as per official GIGYA documentation - https://developers.gigya.com/display/GD/Advanced+Customizations+and+Localization
  // @ts-ignore
  if (!window['gigya']) {
    let GIGYA_API_KEY;
    const publicConfig = useRuntimeConfig().public;
    if (location.href.indexOf('ielts=true') != -1) {
      GIGYA_API_KEY = publicConfig.gigyaSitesKeys.ieltsi;
    } else if (location.href.indexOf('child-registration') != -1) {
      GIGYA_API_KEY = publicConfig.gigyaSitesKeys.children;
    } else if (
      location.href.indexOf('?u=') != -1 ||
      location.href.indexOf('desktop=true') != -1 ||
      location.href.indexOf('desktop-login') != -1
    ) {
      GIGYA_API_KEY = publicConfig.gigyaSitesKeys.dlogin;
    } else {
      GIGYA_API_KEY = publicConfig.gigyaKey;
    }
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src =
      'https://cdns.eu1.gigya.com/js/gigya.js?apikey=' +
      GIGYA_API_KEY +
      '&services=accounts.screenSet';
    s.text =
      "{ defaultRegScreenSet : 'CAMBRIDGEONE-RegistrationLogin', defaultMobileRegScreenSet : 'CAMBRIDGEONE-RegistrationLogin', containerID: 'c1-gigya-container' }";
    document.getElementsByTagName('head')[0].appendChild(s);
  }
  // });
});
