// @ts-nocheck
export const useLoaderService = () => {
  const hideLoader = () => {
    document.getElementById('loader-container').style.display = 'none';
    document
      .getElementById('loader-container')
      .classList.remove('overlay-loader');
  };

  const showLoader = () => {
    document.getElementById('loader-container').style.display = 'flex';
    document
      .getElementById('loader-container')
      .classList.remove('overlay-loader');
  };

  const showOverlayLoader = () => {
    document.getElementById('loader-container').classList.add('overlay-loader');
    document.getElementById('loader-container').style.display = 'flex';
  };

  return { hideLoader, showLoader, showOverlayLoader };
};
